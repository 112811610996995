import React, { useRef } from "react";
import '../../styles/animations.css';

function NewsLetter() {
  console.log('Entrando a NewsLetter--->');
    const videoRef = useRef(null);
  
    const handlePlay = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }}
      const handlePause = () => {
        if (videoRef.current) {
          videoRef.current.pause();
        }
      };

  return (
    <div className="Home NewsLetter" id="argentina-scroll-animate-main">
      <div className="linea-dorada"></div>
      <section className="NewsLetter-title">
        <h1> Novedades Julio 2024</h1>
      </section>
      
      <div className="wrapper-parallax">
        <div className="parallax-container">
          <video ref={videoRef} id="megavideofinca">
            <source
              src="https://gmfp.createch.com.ar/api/newsletter"
              type="video/mp4"
            />
          </video>
        </div>
        <section id="fincaPropia"></section>
        <section className="NewsLetter-video-buttons">
          <button onClick={handlePlay}>Play</button>
          <button onClick={handlePause}>Pause</button>
        </section>
        <div className="linea-dorada"></div>
        <section id="fincaPropia">
        </section>

      </div>
    </div>
  );
}

export default NewsLetter;
